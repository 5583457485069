import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import cls from "classnames";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: "33px",
    height: "20px",
    display: "flex",
    padding: "0px",
    boxSizing: "border-box",
    overflow: "visible",
    margin: "1px 0 0 0",
  },
  switchBase: {
    padding: "3px 0",
    color: "var(--gray-text-color)",
    left: "4px",
    "&$checked": {
      transform: "translateX(12px)",
      color: "#0030B5",
      "& + $track": {
        opacity: 1,
        backgroundColor: "var(--primary-main-color)",
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: "none",
    backgroundColor: "#ffffff",
  },
  track: {
    border: `0`,
    opacity: 1,
    backgroundColor: "var(--light-gray-color)",
    boxSizing: "border-box",
    height: "20px",
    width: "33px",
    borderRadius: "100px",
  },
  checked: {},
}))(Switch);

const CustomSwitch = ({
  label = "",
  checked = false,
  isDisabled = false,
  handleChange,
  id = "",
  showLabel = false,
  isPopupOpen = false,
  setShowConfirmationPopup = () => {},
  hasModel = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  /* useEffect(() => {
    let node = document.getElementById(id);
    if(node && hasModel) {
      if(isPopupOpen === false && isPopupOpen !== null) {
        node.addEventListener("keyup", handleKeyUp)
      } else {
        node.addEventListener("keyup", handleDisable);
        node.removeEventListener("keyup", handleKeyUp);
        if(isPopupOpen === null) {
          setShowConfirmationPopup(false)
        }
      }
    }
    function handleDisable(event) {
      if (event.which == '13') {
        event.preventDefault();
        return false;
      }
    }
    function handleKeyUp(event) {
      if (event.key === "Enter") {
        handleChange();
      }
    }
    return () => {
      node.removeEventListener("keyup", handleKeyUp)
      node.removeEventListener("keyup", handleDisable)
    };
  }, [isPopupOpen]);

  useEffect(() => {
    let node = document.getElementById(id);
    if(node && !hasModel) {
      node.addEventListener("keyup", handleKeyUp)
    }
    function handleKeyUp(event) {
      if (event.key === "Enter") {
        handleChange();
      }
    }
    return () =>node.removeEventListener("keyup", handleKeyUp);
  }, []); */

  return (
    <FormGroup>
      <Typography
        component="div"
        className={`d-flex justify-content-center mb-50 ncustom-switch ${
          isFocused ? "switch-focus" : ""
        }`}
      >
        {showLabel && (
          <span
            className={cls({
              "text-primary": checked === true,
              "text-gray": checked === false,
            })}
          >
            {label ? label : checked ? "On" : "Off"}
          </span>
        )}
        <AntSwitch
          id={id}
          disableRipple={false}
          checked={checked}
          onChange={handleChange}
          name="checkedC"
          disabled={isDisabled}
          inputProps={{
            "aria-label":
              label && label.length > 0 ? label : checked ? "On" : "Off",
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </Typography>
    </FormGroup>
  );
};

export default CustomSwitch;
