import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FooterStyles from "./FooterStyles";
import { Link } from "react-router-dom";
import { useAppState } from "../../../context";
import { routes } from "../../../routes";
import FeedbackDialog from "../../FeedBack";
import ThanksDialog from "../../FeedBack/thanks";
import Close from "../../../assets/svg/close.svg";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import usePersistState from "../../../state/usePersistState";
import { INTERSTRIDE_DEFAULT_LOGO } from "../../../helper/helper";

const Footer = () => {
  const { permissions = {} } = usePersistState();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { handleCloseFooter } = useAppState("app");
  const [model, setModel] = useState(false);
  const [model2, setSubModel] = useState(false);
  const [showPopover, setPopover] = useState(null);

  const handleModel = (status) => {
    setModel(status);
  };

  const subModel = (status) => {
    setSubModel(status);
  };

  useEffect(() => {
    trapFocusOnFooter();
    return () => {
      document.body.classList.remove("hide-scroll");
      document.body.tabIndex = 0;
      handleCloseFooter();
    };
  }, []);

  const trapFocusOnFooter = () => {
    const element = document.getElementById("footer-div");
    let focusableEls = element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    let firstFocusableEl = focusableEls[0];
    let lastFocusableEl = focusableEls[focusableEls.length - 1];
    let KEYCODE_TAB = 9;

    const closeLink = document.getElementById("close-link");
    if (closeLink) closeLink.focus();
    else firstFocusableEl.focus();

    element.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  return (
    <FooterStyles>
      <footer role="contentinfo" tabIndex={0} id="footer-div">
        <div className="footer" onClick={() => handleCloseFooter()}>
          <div className="footer-content" onClick={(e) => e.stopPropagation()}>
            <div className="justify-end flex">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseFooter();
                }}
                className="footer-content__button"
              >
                <img src={Close} alt="Close Dialog" />
              </a>
            </div>
            <ul>
              <li>
                <div className="logo-content">
                  <div className="footer-logo">
                    <img
                      src={INTERSTRIDE_DEFAULT_LOGO}
                      alt="Interstride logo"
                    />
                    <div className="Success beyond boundaries."></div>
                  </div>
                  <div className="tag-line">Success beyond boundaries.</div>
                </div>
              </li>
              <li>
                <h2 className="footer-title">Thrive</h2>
                <Link to={routes.JOBS.DASHBOARD} className="link-focus">
                  Jobs
                </Link>
                {permissions["chat"] && (
                  <Link to={routes.NETWORK.DISCOVER} className="link-focus">
                    Network
                  </Link>
                )}
                {permissions["topics"] && (
                  <Link to={routes.NETWORK.TOPICS} className="link-focus">
                    Communities
                  </Link>
                )}
                {permissions["country_insights"] && (
                  <Link
                    to={routes.JOBS.COUNTRY_INSIGHTS}
                    className="link-focus"
                  >
                    Country Insights
                  </Link>
                )}
              </li>

              {(permissions["webinar"] ||
                permissions["courses"] ||
                permissions["resources"] ||
                permissions["student_services"]) && (
                <li>
                  <h2 className="footer-title">Learn</h2>
                  {permissions["webinar"] && (
                    <Link to={routes.LEARN.WEBINARS} className="link-focus">
                      Webinars
                    </Link>
                  )}
                  {permissions["courses"] && (
                    <Link to={routes.LEARN.COURSES} className="link-focus">
                      Courses
                    </Link>
                  )}
                  {permissions["resources"] && (
                    <Link to={routes.LEARN.RESOURCES} className="link-focus">
                      Resources
                    </Link>
                  )}
                  {permissions["student_services"] && (
                    <Link
                      to={routes.LEARN.STUDENT_SERVICES}
                      className="link-focus"
                    >
                      Student Services
                    </Link>
                  )}
                </li>
              )}

              {(permissions["gameplan"] ||
                permissions["checklist"] ||
                permissions["appointment"]) && (
                <li>
                  <h2 className="footer-title">Tools</h2>
                  {permissions["gameplan"] && (
                    <Link to={routes.TOOLS.CAREER} className="link-focus">
                      Career Gameplan
                    </Link>
                  )}
                  {permissions["checklist"] && (
                    <Link to={routes.TOOLS.CHECKLIST} className="link-focus">
                      Checklists
                    </Link>
                  )}
                  {permissions["appointment"] && (
                    <Link to={routes.TOOLS.MEET} className="link-focus">
                      Meet
                    </Link>
                  )}
                </li>
              )}

              <li>
                <h2 className="footer-title">Account</h2>
                <Link to={routes.PROFILE.PATH} className="link-focus">
                  View Profile
                </Link>
                <Link to={routes.NOTIFICATION.DASHBOARD} className="link-focus">
                  Notifications
                </Link>
                <Link to={routes.NOTIFICATION.SETTINGS} className="link-focus">
                  Settings
                </Link>
              </li>
              <li>
                <h2 className="footer-title">Support</h2>
                <Link to={routes.HELP.DASHBOARD} className="link-focus">
                  Help Center
                </Link>
                <Link
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleModel(true);
                  }}
                  className="link-focus"
                >
                  Give Feedback
                </Link>
                <a onClick={handleClick} href="/#" aria-label="Privacy & Terms">
                  Privacy & Terms
                </a>
              </li>
            </ul>
            <div className="copy-right text-gray">
              © {new Date().getFullYear()} Interstride. All rights reserved.
            </div>
          </div>
        </div>
        {model && (
          <FeedbackDialog
            showModel={model}
            handleModel={handleModel}
            subModel={subModel}
          />
        )}
        {model2 && <ThanksDialog showModel={model2} subModel={subModel} />}

        <Popover
          id={id}
          open={open}
          anchorEl={showPopover}
          onClose={handleClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="terms-popover-div">
            <Typography>
              <Link
                to={routes.TERMS_AND_CONDITIONS}
                className="nlink-secondary link-focus"
              >
                Terms & Conditions
              </Link>
            </Typography>
            <Typography>
              <Link
                to={routes.PRIVACY_POLICY}
                className="nlink-secondary link-focus"
              >
                Privacy Policy
              </Link>
            </Typography>
            {accessibilityHelp == "true" && (
              <div className="close-button">
                <ButtonCustom onClick={handleClose}>Close</ButtonCustom>
              </div>
            )}
          </div>
        </Popover>
      </footer>
    </FooterStyles>
  );
};

export default Footer;
