import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ShimmerThumbnail, ShimmerText } from "react-shimmer-effects";

import MTopicsStyles from "./MTopicsStyles";
import TopicRight from "../../../components/Topics/TopicRight/TopicRight";
import TopicMiddle from "../../../components/Topics/TopicMiddle/TopicMiddle";
import MyTopic from "../../../components/Topics/MyTopics/MyTopic/MyTopic";
import Dialog from "../../../components/Topics/MyTopics/YourTopic/Dialog";
import Container from "../../../components/Container/Container";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import HomeFeedEffect from "../../../components/effects/HomeFeedEffect/HomeFeedEffect";
import { createFreshChatPreview } from "../../../utils/common";
import {
  getFollowingTopics,
  getTopicDetailsAPI,
  getTopicPendingRequestAPI,
  AcceptDeclineTopicRequestAPI,
  readTopicNotificationAPI,
  getTopicFeed,
  getTopicPostReactions,
  suggestCommunityName,
  getSavedTopicPosts,
} from "../../../services/topicServices";
import RecommendationsDetails from "../../../components/NetworkDashboard/RecommendationsDetails/RecommendationsDetails";
import { allNotificationsCountAPI } from "../../../services/notificationServices";
import usePersistState from "../../../state/usePersistState";
import { routes } from "../../../routes";
import { favorite_Unfavorite_NetworksAPI } from "../../../services/networkServices";
import { getFavoriteNetworksAPI } from "../../../services/networkServices";
import { toastify } from "../../../helper/helper";
import { communitiesFilterOptions } from "../../../utils/common";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import { communitiesTabOptions } from "../../../utils/common";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import SuggestedTopics from "../../../components/Topics/SuggestedTopics/SuggestedTopics";

import savePinIcon from "../../../assets/svg/save-pin.svg";
import arrowRight from "../../../assets/svg/arrow-right-2.svg";
import backIcon from "../../../assets/svg/back-arrow-v2.svg";

const INITIAL_STATE_SUGGEST_COMMUNITY_MODAL = {
  open: false,
  topicName: "",
  apiLoading: false,
};

const MTopics = () => {
  const [showModel, setModel] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [stateSuggestCommunityModal, setStateSuggestCommunityModal] = useState(
    INITIAL_STATE_SUGGEST_COMMUNITY_MODAL
  );

  const query = new URLSearchParams(useLocation().search);
  const urlTopicId = parseInt(query.get("topicId") || "-1");
  const navigate = useNavigate();

  const refActiveTopicIdAPI = useRef(undefined);

  const {
    activeFilterTopics,
    setActiveFilterTopics,
    activeTopicId,
    setActiveTopicId,
    setLoadingTopicPosts,
    setTopicPosts,
    changeTab,
    followedTopics,
    setFollowingAndSuggestedTopics,
    topicPosts,
    loadingTopicPosts,
    updateReactionsData,
    setPreApiCallSavedTopic,
    navigateSavedToFeedHandler,
    navigateSavedToFollowing,
    setTopicPendingRequest,
    managePendingRequest,
  } = useAppState("topic");

  const { setFavoriteUsers, setAllNotifications } = usePersistState();
  const {
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    setFavoriteUsersCount,
  } = useAppState("chat");
  const { discoverNetworks } = useAppState("network");

  const [callingGetMyTopicsAPI, refreshGetMyTopicsAPI] =
    usePromise(getFollowingTopics);
  const [callingGetTopicFeedAPI, refreshGetTopicFeedAPI] =
    usePromise(getTopicFeed);
  const [callingGetTopicPendingRequestAPI, refreshGetTopicPendingRequestAPI] =
    usePromise(getTopicPendingRequestAPI);
  const [
    callingAcceptDeclineTopicRequestAPI,
    refreshAcceptDeclineTopicRequestAPI,
  ] = usePromise(AcceptDeclineTopicRequestAPI);
  const [, refreshReadTopicNotificationAPI] = usePromise(
    readTopicNotificationAPI
  );
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);
  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [callingGetAllSavedPosts, refreshGetAllSavedPosts] =
    usePromise(getSavedTopicPosts);

  const getAllNotificationsCountAPI = async () => {
    const response = await allNotificationsCountAPI();
    if (response?.success) {
      setAllNotifications(response.data); // Update Persist State
    }
  };

  const fetchTopicDetailsAPI = async (
    topicId = null,
    page = 1,
    per_page = 10
  ) => {
    const response = await getTopicDetailsAPI(topicId, page, per_page);
    if (response?.success) {
      // This check will avoid race condition on multiple concurrent API calls
      if (refActiveTopicIdAPI.current === response?.data?.topic_id) {
        setTopicPosts(response.data, "topic"); // Update state
        refreshGetTopicPendingRequestAPI(activeTopicId); // Get topic pending requests
        refreshReadTopicNotificationAPI(activeTopicId); // Read current topic notifications
        getAllNotificationsCountAPI(); // Get updated notifications counts
      }
    }
  };

  const acceptRejectRequest = (data) => {
    refreshAcceptDeclineTopicRequestAPI(data);
  };

  const handleClick = () => {
    setModel(!showModel);
  };

  // On Component load:
  useEffect(() => {
    refreshGetMyTopicsAPI();

    // On DOM load, set Community filter to all
    if (activeFilterTopics !== communitiesFilterOptions[0])
      setActiveFilterTopics(communitiesFilterOptions[0]);

    // On Component unmount
    return () => {
      // Call All Notification Count API
      (async () => {
        try {
          const response = await allNotificationsCountAPI();
          if (response.success) {
            // Update Persist State
            setAllNotifications(response.data);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetMyTopicsAPI.hasFetched() &&
      callingGetMyTopicsAPI.hasErrors() === false &&
      callingGetMyTopicsAPI.data()?.data?.success
    ) {
      const followedTopics =
        callingGetMyTopicsAPI.data()?.data?.data?.topics || [];
      const suggestedTopics =
        callingGetMyTopicsAPI.data()?.data?.data?.suggested_topics || [];

      if (urlTopicId !== -1 && followedTopics?.length > 0) {
        const index = followedTopics?.findIndex(
          (topic) => topic.id === urlTopicId
        );
        if (index !== -1) {
          setActiveTopicId(urlTopicId);
          refreshReadTopicNotificationAPI(urlTopicId);
        }
      }

      // Resets query parametes in state if present
      if (urlTopicId !== -1) {
        navigate(routes.NETWORK.TOPICS);
      }

      setFollowingAndSuggestedTopics(followedTopics, suggestedTopics);
      setModel(followedTopics?.length > 0 ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetMyTopicsAPI.isFetching()]);

  useEffect(() => {
    !loadingTopicPosts && setLoadingTopicPosts(true);
    if (activeTopicId) {
      refActiveTopicIdAPI.current = activeTopicId;
      fetchTopicDetailsAPI(activeTopicId);
    } else if (activeTopicId === null && urlTopicId === -1) {
      refActiveTopicIdAPI.current = null;
      refreshGetTopicFeedAPI();
    } else {
      refActiveTopicIdAPI.current = undefined; // Reset Ref
    }
    // If active user present then reset it
    activeUser !== null && setActiveUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTopicId]);

  useEffect(() => {
    if (!loadingTopicPosts && topicPosts?.posts?.length > 0) {
      (async () => {
        let param = "?";
        topicPosts.posts.forEach((post, index) => {
          if (!post.details)
            param += `${index !== 0 ? "&" : ""}topic_post_ids[]=${post?.id}`;
        });

        // Call API
        const response = await getTopicPostReactions(param);

        // Update app state
        if (response.success) {
          updateReactionsData(response?.posts);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicPosts?.posts?.length]);

  useEffect(() => {
    if (
      callingGetTopicPendingRequestAPI.hasFetched() &&
      callingGetTopicPendingRequestAPI.hasErrors() === false &&
      callingGetTopicPendingRequestAPI.data() &&
      callingGetTopicPendingRequestAPI.data().data
    ) {
      setTopicPendingRequest(callingGetTopicPendingRequestAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetTopicPendingRequestAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetTopicFeedAPI.hasFetched() &&
      callingGetTopicFeedAPI.hasErrors() === false
    ) {
      setTopicPosts(callingGetTopicFeedAPI.data().data, "feed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetTopicFeedAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetAllSavedPosts.hasFetched() &&
      callingGetAllSavedPosts.hasErrors() === false
    ) {
      setTopicPosts(
        callingGetAllSavedPosts.data().data,
        activeTopicId ? "topic-saved" : "all-saved"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetAllSavedPosts.isFetching()]);

  useEffect(() => {
    if (
      callingAcceptDeclineTopicRequestAPI.hasFetched() &&
      callingAcceptDeclineTopicRequestAPI.hasErrors() === false &&
      callingAcceptDeclineTopicRequestAPI.data().data &&
      callingAcceptDeclineTopicRequestAPI.data()
    ) {
      managePendingRequest(callingAcceptDeclineTopicRequestAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingAcceptDeclineTopicRequestAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
      setFavoriteUsers(favourite_list.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  const LoadingTopicsDashboardEffect = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          {Array.from(Array(10).keys()).map((_, index) => (
            <ShimmerThumbnail height={50} key={index} />
          ))}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ShimmerText line={4} />
          <HomeFeedEffect />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <ShimmerText line={20} />
        </Grid>
      </Grid>
    );
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation();
    setShowUserPopup(false);
  };

  const startChatWithNewUser = (e, item) => {
    if (e) e.stopPropagation();
    try {
      const { id } = item;
      const user = getChatContactListItem(id, discoverNetworks);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };
  const handleFavorite = (e, userId) => {
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  const handleSubmitSuggestCommunityName = async () => {
    setStateSuggestCommunityModal((current) => ({
      ...current,
      apiLoading: true,
    }));
    const response = await suggestCommunityName({
      community_name: stateSuggestCommunityModal.topicName?.trim(),
    });
    if (response?.status === 200) {
      setStateSuggestCommunityModal(INITIAL_STATE_SUGGEST_COMMUNITY_MODAL);
      toastify("success", "Suggestion submitted successfully");
    } else {
      setStateSuggestCommunityModal((current) => ({
        ...current,
        apiLoading: false,
      }));
      toastify("error");
    }
  };

  const handleClickAllSavedPosts = async () => {
    setPreApiCallSavedTopic("all-saved");
    refreshGetAllSavedPosts();
  };

  const handleBackToFeedFromAllSaved = () => {
    navigateSavedToFeedHandler();
    refreshGetTopicFeedAPI();
  };

  const handleBackToFollowingTopics = () => {
    navigateSavedToFollowing();
    fetchTopicDetailsAPI(activeTopicId);
  };

  return (
    <MTopicsStyles>
      <Container>
        <div className="mytopic-mainpage">
          {callingGetMyTopicsAPI.isFetching() ? (
            <LoadingTopicsDashboardEffect />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                {topicPosts?.type === "all-saved" ||
                topicPosts?.type === "topic-saved" ? (
                  <div className="mytopic-mainpage__saved">
                    <ButtonCustom
                      className="shadow-btn"
                      onClick={() =>
                        topicPosts?.type === "all-saved"
                          ? handleBackToFeedFromAllSaved()
                          : handleBackToFollowingTopics()
                      }
                    >
                      <img alt="arrow" src={backIcon} />
                      <span>
                        {topicPosts?.type === "all-saved"
                          ? "Back to Community Feed"
                          : "Back to My Community"}
                      </span>
                    </ButtonCustom>
                  </div>
                ) : (
                  <div className="mytopic-mainpage__discover">
                    <ButtonCustom
                      className="shadow-btn"
                      onClick={() => changeTab(communitiesTabOptions[1])}
                    >
                      <span>Discover Communities</span>
                      <img alt="arrow" src={arrowRight} />
                    </ButtonCustom>
                  </div>
                )}

                {topicPosts?.type !== "all-saved" &&
                  topicPosts?.type !== "topic-saved" && (
                    <MyTopic
                      itemList={followedTopics}
                      refreshGetMyTopicsAPI={refreshGetMyTopicsAPI}
                    />
                  )}
              </Grid>
              <Grid item xs={6}>
                <TopicMiddle
                  acceptRejectRequest={acceptRejectRequest}
                  setActiveUser={setActiveUser}
                  setShowUserPopup={setShowUserPopup}
                  refreshGetTopicFeedAPI={refreshGetTopicFeedAPI}
                  fetchTopicDetailsAPI={fetchTopicDetailsAPI}
                  refreshGetAllSavedPosts={refreshGetAllSavedPosts}
                />
              </Grid>
              <Grid item xs={3}>
                {loadingTopicPosts ? (
                  <div style={{ marginTop: "74px" }}>
                    <ShimmerText line={20} />
                  </div>
                ) : topicPosts.type === "topic" ? (
                  <TopicRight
                    refreshGetAllSavedPosts={refreshGetAllSavedPosts}
                  />
                ) : topicPosts.type === "feed" ? (
                  <>
                    <div
                      className="mytopic-mainpage__suggest-topic"
                      onClick={() =>
                        setStateSuggestCommunityModal((current) => ({
                          ...current,
                          open: true,
                        }))
                      }
                    >
                      <span>Suggest a Community</span>
                    </div>
                    {topicPosts?.saved_posts > 0 && (
                      <div
                        className="mytopic-mainpage__view-pinned"
                        onClick={handleClickAllSavedPosts}
                      >
                        <img src={savePinIcon} alt="save" />
                        <span>All saved posts ({topicPosts?.saved_posts})</span>
                      </div>
                    )}
                    <SuggestedTopics
                      refreshGetMyTopicsAPI={refreshGetMyTopicsAPI}
                    />
                  </>
                ) : (
                  <></>
                )}
                {showUserPopup && activeUser && (
                  <div className="mytopic-mainpage__recommendation-detail-wrapper">
                    <RecommendationsDetails
                      details={activeUser}
                      handleClose={handleClose}
                      handleMessage={startChatWithNewUser}
                      handleFavorite={handleFavorite}
                      isTopicUser={true}
                    />
                  </div>
                )}
              </Grid>
              <Dialog isOpen={showModel} handleClose={handleClick} />
              <CustomDialog
                open={stateSuggestCommunityModal.open}
                title="Suggest a Community"
                handleClose={() =>
                  setStateSuggestCommunityModal(
                    INITIAL_STATE_SUGGEST_COMMUNITY_MODAL
                  )
                }
                className="suggest-topic-modal"
              >
                <div className="suggest-topic-modal__body">
                  <div className="suggest-topic-modal__body__text">
                    <TextboxCustom
                      max={100}
                      value={stateSuggestCommunityModal.topicName}
                      onChange={(e) =>
                        setStateSuggestCommunityModal((current) => ({
                          ...current,
                          topicName: e.target.value,
                        }))
                      }
                      label="Community name"
                    />
                  </div>
                  <div className="suggest-topic-modal__body__button">
                    <ButtonCustom
                      isDisabled={
                        stateSuggestCommunityModal.topicName?.trim()?.length ===
                        0
                      }
                      isSubmitting={stateSuggestCommunityModal.apiLoading}
                      onClick={handleSubmitSuggestCommunityName}
                      width={100}
                      height={35}
                    >
                      Submit
                    </ButtonCustom>
                  </div>
                </div>
              </CustomDialog>
            </Grid>
          )}
        </div>
      </Container>
    </MTopicsStyles>
  );
};

export default MTopics;
