import React, { useState, useEffect, useRef } from "react";
import debounce from "debounce-promise";
import moment from "moment";
import { Link } from "react-router-dom";

import VisaSearchFilterStyles from "./VisaSearchFilterStyles";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { getYearList } from "../../../../helper/helper";
import { Grid, Popover, Typography } from "@mui/material";
import Switch from "../../../atoms/Switch/Switch";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CardCustom from "../../../CardCustom/CardCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import JobExperienceModal from "../CompanyInfo/Common/JobExperienceModal";
import {
  getIndustryFilterForCompany,
  getCompanyFilterForCompany,
  getStateFilterForCompany,
  getCityFilterForCompany,
  getJobTitleFilterForCompany,
  getJobClassificationFilterForCompany,
} from "../../../../services/JobServices";

import FilterIcon from "../../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../../assets/svg/filled-filter-Icon.svg";
import QuestionIcon from "../../../QuestionIcon/QuestionIcon";

export const presetFilters = [
  {
    value: "top_employers_experience_level",
    label: "Top entry-level employers",
  },
  { value: "top_employers_new_york_city", label: "Top NYC employers" },
  {
    value: "top_employers_california",
    label: "Top CA employers",
  },
  {
    value: "top_employers_accounting",
    label: "Top Accounting employers",
  },
];

const visaOptions = [
  { value: "h1b", label: "H-1B" },
  { value: "greencard", label: "Green Card" },
  { value: "cpt", label: "F-1 CPT" },
  { value: "opt", label: "F-1 OPT" },
  { value: "stem", label: "F-1 STEM OPT" },
];

const industryTypeOptions = [
  { value: "industry", label: "Industry" },
  { value: "company", label: "Company" },
];

const yearOptions = [
  { value: 2017, label: "2017" },
  { value: 2018, label: "2018" },
  { value: 2019, label: "2019" },
  { value: 2020, label: "2020" },
];

const h1bGreenCardYearOptions = [
  { value: 2024, label: "2024" },
  { value: 2023, label: "2023" },
];

export const experienceOptions = [
  { value: "entry", label: "Entry" },
  { value: "qualified", label: "Qualified" },
  { value: "experienced", label: "Experienced" },
  { value: "fully_competent", label: "Fully Competent" },
];

const functionOptions = [
  { value: "Accounting", label: "Accounting" },
  { value: "Consulting", label: "Consulting" },
  { value: "Corporate", label: "Corporate" },
  { value: "Finance", label: "Finance" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing", label: "Marketing" },
  { value: "Operations/Logistics", label: "Operations/Logistics" },
  { value: "Other", label: "Other" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Rotational Program", label: "Rotational Program" },
  { value: "Technical", label: "Technical" },
];

const VisaSearchFilter = ({
  filter,
  setFilter,
  onSearchClick,
  onClearAllClick,
  onPresetFilterClick,
  preserveFilters = false,
}) => {
  const {
    job_classification,
    job_title,
    visa,
    year,
    industryType = "industry",
    industry,
    company,
    city,
    state,
    experience,
    graduationYear,
    functionFilter,
  } = filter;
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const [openFilter, toggleFilter] = useState(false);

  const [callingIndustryFilterAPI, refreshIndustryFilterAPI] = usePromise(
    getIndustryFilterForCompany
  );
  const [industryOptions, setIndustryOptions] = useState([]);

  const [showVisaPopupover, setVisaPopupover] = useState(false);
  const [showExperiencePopup, setExperiencePopup] = useState(false);

  const [companyCounter, setCompanyCounter] = useState(1);
  const [stateCounter, setStateCounter] = useState(1);
  const [cityCounter, setCityCounter] = useState(1);
  const [jobClassificationCounter, setJobClassificationCounter] = useState(1);
  const [jobTitleCounter, setJobTitleCounter] = useState(1);
  const [jobClassificationH1bCounter, setJobClassificationH1bCounter] =
    useState(1);

  const [showClassificationPopover, setClassificationPopover] = useState(null);
  const openClassification = Boolean(showClassificationPopover);
  const idClassification = openClassification ? "simple-popover" : undefined;
  const [showTitlePopover, setTitlePopover] = useState(null);
  const openTitle = Boolean(showTitlePopover);
  const idTitle = openTitle ? "simple-popover" : undefined;

  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(true);

  const handleClassificationClose = () => {
    setClassificationPopover(null);
  };

  const handleClassificationClick = (event) => {
    event.preventDefault();
    setClassificationPopover(event.currentTarget);
  };

  const handleTitleClose = () => {
    setTitlePopover(null);
  };

  const handleTitleClick = (event) => {
    event.preventDefault();
    setTitlePopover(event.currentTarget);
  };

  useEffect(() => {
    if (!preserveFilters)
      setFilter({
        year:
          visa !== null && (visa === "h1b" || visa === "greencard")
            ? 2024
            : 2020,
      });
    // To get initial filters based on year
    refreshYearOptions();
  }, []);

  useEffect(() => {
    if (visa === "cpt" || visa === "opt" || visa === "stem") {
      setShowFilterOption(false);
    } else {
      setShowFilterOption(true);
    }
  }, [visa]);

  const isInitialIndustryMount = useRef(true);
  useEffect(() => {
    if (isInitialIndustryMount.current) {
      isInitialIndustryMount.current = false;
    } else {
      if (visa === "h1b" || visa === "greencard") {
        if (industryType !== null && industryType === "industry") {
          setFilter({
            company: null,
          });
          setIndustryOptions([]);
          refreshIndustryFilterAPI({ company_type: visa, year });
        } else {
          setFilter({
            industry: null,
          });
          refreshIndustryFilterAPI({ company_type: visa, year });
        }
      }
    }
  }, [industryType]);

  const refreshYearOptions = () => {
    if (visa === "h1b" || visa === "greencard") {
      if (industryType !== null && industryType === "industry") {
        setIndustryOptions([]);
        refreshIndustryFilterAPI({ company_type: visa, year });
      }
    }

    setCompanyCounter(companyCounter + 1);
    setCityCounter(cityCounter + 1);
    setJobClassificationH1bCounter(jobClassificationH1bCounter + 1);
    setJobClassificationCounter(jobClassificationCounter + 1);
    setJobTitleCounter(jobTitleCounter + 1);
  };

  const refreshVisaOptions = () => {
    if (visa === "h1b" || visa === "greencard") {
      if (industryType !== null && industryType === "industry") {
        setIndustryOptions([]);
        refreshIndustryFilterAPI({
          company_type: visa,
          year,
        });
      }
    }

    setCompanyCounter(companyCounter + 1);
    setStateCounter(stateCounter + 1);
    setCityCounter(cityCounter + 1);
    setJobClassificationH1bCounter(jobClassificationH1bCounter + 1);
    setJobClassificationCounter(jobClassificationCounter + 1);
    setJobTitleCounter(jobTitleCounter + 1);
  };

  const isInitialVisaMount = useRef(true);
  useEffect(() => {
    if (isInitialVisaMount.current) {
      isInitialVisaMount.current = false;
    } else {
      refreshVisaOptions();
      if (!preserveFilters)
        setFilter({
          job_classification: null,
          job_title: null,
          city: null,
          state: null,
          experience: null,
          industryType:
            visa !== null && (visa === "h1b" || visa === "greencard")
              ? "industry"
              : null,
          industry: null,
          company: null,
          graduationYear: null,
          functionFilter: null,
          year:
            visa !== null && (visa === "h1b" || visa === "greencard")
              ? 2024
              : 2020,
        });
    }
  }, [visa]);

  const isInitialYearMount = useRef(true);
  useEffect(() => {
    if (isInitialYearMount.current) {
      isInitialYearMount.current = false;
    } else {
      refreshYearOptions();
      if (!preserveFilters)
        setFilter({
          job_classification: null,
          job_title: null,
          city: null,
          state: null,
          industryType: "industry",
          industry: null,
          company: null,
          experience: null,
        });
    }
  }, [year]);

  useEffect(() => {
    // setFilter({
    //   city: null,
    // });

    if (state !== null) setCityCounter(cityCounter + 1);
  }, [state]);

  useEffect(() => {
    if (
      callingIndustryFilterAPI.hasFetched() &&
      callingIndustryFilterAPI.hasErrors() === false &&
      callingIndustryFilterAPI.data()
    ) {
      const options = callingIndustryFilterAPI
        .data()
        .map((rec) => ({ value: rec.id, label: rec.industry_name }));
      setIndustryOptions(options);
    }
  }, [callingIndustryFilterAPI.isFetching()]);

  const handleChange = (e, name) => {
    setFilter({
      [name]: e.value,
    });
  };

  const handlePopoverClick = (event) => {
    event.preventDefault();
    setVisaPopupover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setVisaPopupover(null);
  };

  const openPopover = Boolean(showVisaPopupover);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const getJobTitle = async (inputValue) => {
    const data = await getJobTitleFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.name,
    }));
  };

  const debouncedLoadJobTitleOptions = debounce(getJobTitle, 2000, {
    leading: true,
  });

  const getJobClassification = async (inputValue) => {
    const data = await getJobClassificationFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.job_title_name,
    }));
  };

  const debouncedLoadJobClassificationOptions = debounce(
    getJobClassification,
    2000,
    {
      leading: true,
    }
  );

  const getCompany = async (inputValue) => {
    const data = await getCompanyFilterForCompany({
      company_type: visa,
      year,
      search_field: inputValue,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.company_name,
    }));
  };

  const debouncedLoadCompanyOptions = debounce(getCompany, 2000, {
    leading: true,
  });

  const getState = async (inputValue) => {
    const data = await getStateFilterForCompany({
      company_type: visa,
      search_field: inputValue,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.state_name,
    }));
  };

  const debouncedLoadStateOptions = debounce(getState, 2000, {
    leading: true,
  });

  const getCity = async (inputValue) => {
    const data = await getCityFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
      state_ids: state !== null ? [state?.value] : [],
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.city_name,
    }));
  };

  const debouncedLoadCityOptions = debounce(getCity, 2000, {
    leading: true,
  });

  return (
    <VisaSearchFilterStyles>
      <div className="preset-filter--big-screen">
        <h2 className="title-primary mb-25">Popular Filters</h2>
        <div className="preset-filter">
          {presetFilters.map((item) => (
            <a
              href="/#"
              key={item.value}
              className={`preset-filter__item no-underline ${
                filter?.presetFilter === item.value &&
                "preset-filter__item--active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                onPresetFilterClick({
                  presetFilter: item.value,
                  ...(item.value === presetFilters[3].value && {
                    industryCode: industryOptions?.filter(
                      (industry) => industry?.label === "Accounting"
                    )[0]?.value,
                  }),
                });
                setShowMoreFilters(true);
              }}
            >
              <span>{item.label}</span>
            </a>
          ))}
        </div>
      </div>
      <div
        className={`search-filter collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-15">
          Search & filter
          <Link
            to="/#"
            onClick={(e) => {
              e.preventDefault();
              onClearAllClick();
              setCityCounter(cityCounter + 1);
            }}
            className="ngray-link"
            aria-label="Clear all"
          >
            Clear all
          </Link>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon"
          >
            {openFilter ? (
              <img src={FilledFilterIcon} alt="Filter Icon" />
            ) : (
              <img src={FilterIcon} alt="Filter Icon" />
            )}
          </a>
        </h2>
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card overflow-initial">
              <div className="form-field-group">
                <label className="textbox-label">
                  Visa type
                  <QuestionIcon onClick={handlePopoverClick} />
                </label>
                <DropdownCustom
                  name="visa"
                  placeholder="Visa type"
                  value={
                    visa !== null
                      ? visaOptions.find((option) => option.value === visa)
                      : ""
                  }
                  justifyContent="left"
                  options={visaOptions}
                  onChange={(e) => handleChange(e, "visa")}
                />
              </div>
              {visa !== null && (visa === "h1b" || visa === "greencard") && (
                <React.Fragment>
                  <div className="form-field-group mb-15">
                    <label className="textbox-label">Search type</label>
                    <Switch
                      activeSide={
                        visa !== null
                          ? industryTypeOptions.find(
                              (option) => option.value === industryType
                            ).value
                          : ""
                      }
                      leftTitle={"Industry"}
                      leftValue={"industry"}
                      rightTitle={"Company"}
                      rightValue={"company"}
                      variant={"small"}
                      name="visa"
                      setActiveSide={(value) =>
                        handleChange({ value }, "industryType")
                      }
                    />
                  </div>
                  {industryType !== null && industryType === "company" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        autoFocus={true}
                        key={JSON.stringify(companyCounter)}
                        isAsync={true}
                        name="company"
                        label="Companies"
                        placeholder="Enter a company name"
                        justifyContent="left"
                        value={company ? company : null}
                        defaultOptions
                        onChange={(e) => {
                          setFilter({
                            company: e,
                          });
                        }}
                        loadOptions={debouncedLoadCompanyOptions}
                      />
                    </div>
                  )}
                  {industryType !== null && industryType === "industry" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        autoFocus
                        name="industry"
                        label="Industries"
                        placeholder="Enter an industry"
                        justifyContent="left"
                        value={
                          industry !== null
                            ? industryOptions.find(
                                (option) => option.value === industry
                              )
                            : ""
                        }
                        options={industryOptions}
                        onChange={(e) => handleChange(e, "industry")}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}

              {visa !== null && visa === "h1b" && showMoreFilters ? (
                <React.Fragment>
                  <div className="form-field-group">
                    <label className="textbox-label">
                      Job classification
                      <QuestionIcon onClick={handleClassificationClick} />
                    </label>
                    <Popover
                      id={idClassification}
                      open={openClassification}
                      anchorEl={showClassificationPopover}
                      onClose={handleClassificationClose}
                      className="popover-wrapper"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography>
                        Broader classification of job titles
                      </Typography>
                      {accessibilityHelp == "true" && (
                        <div className="close-button">
                          <ButtonCustom onClick={handleClassificationClose}>
                            Got It!
                          </ButtonCustom>
                        </div>
                      )}
                    </Popover>
                    <DropdownCustom
                      key={JSON.stringify(jobTitleCounter)}
                      isAsync={true}
                      name="jobtitle"
                      placeholder="Job classification"
                      justifyContent="left"
                      value={job_title ? job_title : null}
                      defaultOptions
                      onChange={(e) => {
                        setFilter({
                          job_title: e,
                        });
                      }}
                      loadOptions={debouncedLoadJobTitleOptions}
                    />
                  </div>
                </React.Fragment>
              ) : null}

              {visa !== null &&
              (visa === "cpt" || visa === "opt" || visa === "stem") ? (
                <div className="form-field-group">
                  <DropdownCustom
                    name="year"
                    label="Year"
                    justifyContent="left"
                    value={yearOptions.find(
                      (option) =>
                        option.value === (year && year !== null ? year : 2020)
                    )}
                    options={yearOptions}
                    onChange={(e) => handleChange(e, "year")}
                  />
                </div>
              ) : null}

              {showMoreFilters ? (
                <React.Fragment>
                  {visa !== null && visa === "h1b" ? (
                    <React.Fragment>
                      <div className="form-field-group">
                        <label className="textbox-label">
                          Job title
                          <QuestionIcon onClick={handleTitleClick} />
                        </label>
                        <DropdownCustom
                          key={JSON.stringify(jobClassificationH1bCounter)}
                          isAsync={true}
                          name="jobtitle"
                          placeholder="Job Title"
                          justifyContent="left"
                          value={job_classification ? job_classification : null}
                          defaultOptions
                          onChange={(e) => {
                            setFilter({
                              job_classification: e,
                            });
                          }}
                          loadOptions={debouncedLoadJobClassificationOptions}
                        />
                        <Popover
                          id={idTitle}
                          open={openTitle}
                          anchorEl={showTitlePopover}
                          onClose={handleTitleClose}
                          className="popover-wrapper"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Typography>
                            Exact job title sponsored by the employer
                          </Typography>
                          {accessibilityHelp == "true" && (
                            <div className="close-button">
                              <ButtonCustom onClick={handleTitleClose}>
                                Got It!
                              </ButtonCustom>
                            </div>
                          )}
                        </Popover>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {visa !== null &&
                        (visa === "h1b" ||
                          visa === "greencard" ||
                          visa === "alumni") && (
                          <div className="form-field-group">
                            <DropdownCustom
                              key={JSON.stringify(jobClassificationCounter)}
                              isAsync={true}
                              name="jobtitle"
                              label="Job title"
                              justifyContent="left"
                              value={
                                job_classification ? job_classification : null
                              }
                              defaultOptions
                              onChange={(e) => {
                                setFilter({
                                  job_classification: e,
                                });
                              }}
                              loadOptions={
                                debouncedLoadJobClassificationOptions
                              }
                            />
                          </div>
                        )}
                    </React.Fragment>
                  )}
                  {visa !== null && (visa === "h1b" || visa === "greencard") ? (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="year"
                        label="Year"
                        justifyContent="left"
                        value={h1bGreenCardYearOptions.find(
                          (option) =>
                            option.value ===
                            (year && year !== null ? year : 2024)
                        )}
                        options={h1bGreenCardYearOptions}
                        onChange={(e) => handleChange(e, "year")}
                      />
                    </div>
                  ) : (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="year"
                        label="Year"
                        justifyContent="left"
                        value={yearOptions.find(
                          (option) =>
                            option.value ===
                            (year && year !== null ? year : 2020)
                        )}
                        options={yearOptions}
                        onChange={(e) => handleChange(e, "year")}
                      />
                    </div>
                  )}

                  {visa !== null &&
                    (visa === "h1b" || visa === "greencard") && (
                      <div className="form-field-group">
                        <DropdownCustom
                          key={JSON.stringify(stateCounter)}
                          isAsync={true}
                          name="state"
                          label="State"
                          justifyContent="left"
                          defaultOptions
                          value={state ? state : null}
                          onChange={(e) => {
                            setFilter({
                              state: e,
                            });
                          }}
                          loadOptions={debouncedLoadStateOptions}
                        />
                      </div>
                    )}

                  {visa !== null &&
                    (visa === "h1b" ||
                      visa === "greencard" ||
                      visa === "alumni") && (
                      <div className="form-field-group">
                        <DropdownCustom
                          key={JSON.stringify(cityCounter)}
                          isAsync={true}
                          name="city"
                          label="City"
                          justifyContent="left"
                          value={city ? city : null}
                          defaultOptions
                          onChange={(e) => {
                            setFilter({
                              city: e,
                            });
                          }}
                          loadOptions={debouncedLoadCityOptions}
                        />
                      </div>
                    )}

                  {visa !== null && visa === "alumni" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="graduationYear"
                        label="Graduation year"
                        defaultValue={parseInt(moment().format("YYYY"))}
                        justifyContent="left"
                        value={
                          graduationYear !== null
                            ? getYearList.find(
                                (option) => option.value === graduationYear
                              )
                            : ""
                        }
                        options={getYearList}
                        onChange={(e) => handleChange(e, "graduationYear")}
                      />
                    </div>
                  )}

                  {visa !== null && visa === "alumni" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="function"
                        label="Function"
                        justifyContent="left"
                        value={
                          functionFilter !== null
                            ? functionOptions.find(
                                (option) => option.value === functionFilter
                              )
                            : ""
                        }
                        options={functionOptions}
                        onChange={(e) => handleChange(e, "functionFilter")}
                      />
                    </div>
                  )}

                  {visa !== null &&
                    (visa === "h1b" || visa === "greencard") && (
                      <div className="form-field-group">
                        <label className="textbox-label">
                          Experience level
                          <QuestionIcon
                            onClick={(e) => {
                              e.preventDefault();
                              setExperiencePopup(true);
                            }}
                          />
                        </label>
                        <DropdownCustom
                          name="experience"
                          placeholder="Experience level"
                          justifyContent="left"
                          value={
                            experience !== null
                              ? experienceOptions.find(
                                  (option) => option.value === experience
                                )
                              : ""
                          }
                          options={experienceOptions}
                          onChange={(e) => handleChange(e, "experience")}
                        />
                      </div>
                    )}
                </React.Fragment>
              ) : null}
              {showFilterOption ? (
                <div className="mt-20 text-center">
                  <a
                    href="#"
                    className="nshow-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMoreFilters(!showMoreFilters);
                    }}
                    aria-label="Show more filters"
                  >
                    <span>{!showMoreFilters ? "Show more" : ""}</span>
                  </a>
                  <a
                    href="#"
                    aria-label="Show more filters"
                    className={`nshow-link ${!showMoreFilters ? "" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMoreFilters(!showMoreFilters);
                    }}
                  >
                    <span>{!showMoreFilters ? "" : "Show less"}</span>
                  </a>
                </div>
              ) : null}

              <div className="searchBtn">
                <ButtonCustom
                  onClick={() => {
                    toggleFilter(false);
                    onSearchClick();
                  }}
                  width="250"
                >
                  Search
                </ButtonCustom>
              </div>
            </CardCustom>
          </Grid>
        </Grid>

        <Popover
          id={popoverId}
          open={openPopover}
          anchorEl={showVisaPopupover}
          onClose={handlePopoverClose}
          className="popover-wrapper npopover-wrapper no-top-left-corner-popover"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <h2>Visa types on Interstride</h2>
          <Typography>
            We source this information from the U.S. Department of Labor, SEVIS,
            USCIS
          </Typography>
          {accessibilityHelp == "true" && (
            <div className="close-button">
              <ButtonCustom onClick={handlePopoverClose}>Got It!</ButtonCustom>
            </div>
          )}
        </Popover>

        {showExperiencePopup && (
          <JobExperienceModal onClose={() => setExperiencePopup(false)} />
        )}
      </div>
    </VisaSearchFilterStyles>
  );
};
export default VisaSearchFilter;
