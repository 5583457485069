import React, { useState, useEffect } from "react";
import { Avatar, Tooltip, Chip } from "@mui/material";

import MyTopicStyles from "./MyTopicStyles";
import EmptyMiddleBox from "../../../EmptyModdleBox/EmptyMiddleBox";
import usePersistState from "../../../../state/usePersistState";
import { communitiesFilterOptions } from "../../../../utils/common";
import { useAppState } from "../../../../context";
import InitialAvatar from "../../../InitialAvatar/InitialAvatar";
import { favouriteTopic } from "../../../../services/topicServices";
import { toastify } from "../../../../helper/helper";

import StarIcon from "../../../StarIcon/StarIcon";
import ArrowIcon from "../../../../assets/svg/down-arrow.svg";

const TOPIC_LINE_ONE_LENGTH = 16;
const TOPIC_LINE_TWO_LENGTH = 47;

const MyTopic = ({ itemList, refreshGetMyTopicsAPI }) => {
  const [openFilter, toggleFilter] = useState(false);

  const { topic_specific_notifcations_count, users } = usePersistState();
  const {
    activeFilterTopics,
    setActiveFilterTopics,
    activeTopicId,
    setActiveTopicId,
    updateTopicFavouriteStatus,
  } = useAppState("topic");

  useEffect(() => {
    if (activeTopicId !== null) toggleFilter(false);
  }, [activeTopicId]);

  const findTopicCount = (id) => {
    if (topic_specific_notifcations_count?.length === 0) {
      return "";
    } else {
      for (let i = 0; i < topic_specific_notifcations_count?.length; i++) {
        if (topic_specific_notifcations_count[i][0] === id) {
          return `${topic_specific_notifcations_count[i][1]}`;
        }
      }
    }
  };

  const handleFavorite = async (item = {}) => {
    if (item.id) {
      const response = await favouriteTopic(item.id);
      if (response?.status === 200) {
        toastify("success", response?.data?.message);
        // Update local app state
        updateTopicFavouriteStatus(item.id);
      }
    } else {
      toastify("error");
    }
  };

  // This function ensures Community name is truncated enough to be displayed within UI
  const renderTopicName = (topicName = "", isAdmissionTag = false) => {
    if (isAdmissionTag) {
      return topicName?.length > TOPIC_LINE_ONE_LENGTH
        ? topicName?.slice(0, TOPIC_LINE_ONE_LENGTH) + "..."
        : topicName;
    } else {
      return topicName?.length > TOPIC_LINE_TWO_LENGTH
        ? topicName?.slice(0, TOPIC_LINE_TWO_LENGTH) + "..."
        : topicName;
    }
  };

  return (
    <MyTopicStyles>
      <div
        className={`my-topic-page collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-15 d-flex">
          My Communities
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon arrow"
          >
            <img src={ArrowIcon} alt="Arrow Icon" />
          </a>
        </h2>
        <div className="list cursor-pointer topics-categories">
          {users?.admission_user_type && (
            <div className="my-topic-page__filters">
              <div
                className={`my-topic-page__filters__item ${
                  activeFilterTopics === communitiesFilterOptions[0]
                    ? "my-topic-page__filters__item--active"
                    : ""
                }`}
                onClick={() => {
                  setActiveFilterTopics(communitiesFilterOptions[0]);
                  refreshGetMyTopicsAPI(communitiesFilterOptions[0]);
                }}
              >
                <span>All</span>
              </div>
              <div
                className={`my-topic-page__filters__item ${
                  activeFilterTopics === communitiesFilterOptions[1] &&
                  "my-topic-page__filters__item--active"
                }`}
                onClick={() => {
                  setActiveFilterTopics(communitiesFilterOptions[1]);
                  refreshGetMyTopicsAPI(communitiesFilterOptions[1]);
                }}
              >
                <span>Student</span>
              </div>
              <div
                className={`my-topic-page__filters__item ${
                  activeFilterTopics === communitiesFilterOptions[2] &&
                  "my-topic-page__filters__item--active"
                }`}
                onClick={() => {
                  setActiveFilterTopics(communitiesFilterOptions[2]);
                  refreshGetMyTopicsAPI(communitiesFilterOptions[2]);
                }}
              >
                <span>Admissions</span>
              </div>
            </div>
          )}
          <div className="my-topic-page__topic">
            {itemList?.length > 0 ? (
              itemList.map((item, index) => (
                <div
                  className={`my-topic-page__topic__card ${
                    item.id === activeTopicId &&
                    "my-topic-page__topic__card--active"
                  }`}
                  key={index}
                  onClick={() =>
                    activeTopicId === item?.id
                      ? setActiveTopicId(null)
                      : setActiveTopicId(item?.id)
                  }
                >
                  <div className="my-topic-page__topic__card__avatar">
                    {item?.topic_icon?.length > 0 ? (
                      <Avatar src={item.topic_icon} alt="Remy Sharp" si />
                    ) : (
                      <InitialAvatar title={item.topic_name} index={index} />
                    )}
                  </div>
                  <div className="my-topic-page__topic__card__title">
                    <div className="my-topic-page__topic__card__title__container">
                      <Tooltip title={item?.topic_name}>
                        <span
                          className={`my-topic-page__topic__card__title__container__topic-name ${
                            item.id === activeTopicId &&
                            "my-topic-page__topic__card__title__container__topic-name--active"
                          }`}
                        >
                          {renderTopicName(
                            item?.topic_name,
                            item?.topic_portal_type
                          )}
                        </span>
                      </Tooltip>
                      {item?.topic_portal_type && (
                        <div className="ambassador-tag-community">
                          <div className="ambassador-tag-community__component">
                            <span>Admissions</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {findTopicCount(item.id) && (
                    <div className="my-topic-page__topic__card__notification">
                      <Chip label={findTopicCount(item.id)} />
                    </div>
                  )}
                  <StarIcon
                    className="my-topic-page__topic__card__favorite"
                    aria-label={`Remove ${item.topic_name} from favourites`}
                    variant={item?.favourite ? "filled" : "un-filled"}
                    onClick={() => handleFavorite(item)}
                  />
                </div>
              ))
            ) : (
              <EmptyMiddleBox title="Looks like you haven't created or followed any Community." />
            )}
          </div>
        </div>
      </div>
    </MyTopicStyles>
  );
};
export default MyTopic;
