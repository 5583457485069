import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import FilterListStyles from "./FilterListStyles";
import usePersistState from "../../../state/usePersistState";
import CardCustom from "../../CardCustom/CardCustom";
import EmptyList from "../../EmptyList/EmptyList";
import { processJobLocationTime } from "../../../helper/helper";
import { routes } from "../../../routes";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";

const FilterList = ({ searchesList, applySearch, loading, backPage }) => {
  const navigate = useNavigate();
  const { jobsCountryList } = usePersistState();
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    if (searchesList && searchesList.length > 0) {
      setSelectedIndex(0);
    }
  }, [searchesList]);

  useEffect(() => {
    if (selectedIndex >= 0) applySearch(searchesList[selectedIndex]);
  }, [selectedIndex]);

  const renderFilterList = (data, i) => {
    const country =
      data.country !== null
        ? jobsCountryList.find((rec) => rec.value === data.country)
        : "";
    return (
      <CardCustom
        className={`job-alert-card ${i === selectedIndex ? "active" : ""}`}
        onClick={() => {
          setSelectedIndex(i);
        }}
      >
        <div className="title">{data.title}</div>
        <div className="sub-title">
          {processJobLocationTime({
            city: data?.city,
            state: data?.state,
            country:
              country?.label === "United States" ||
              country?.label === "USA" ||
              country?.label === "US"
                ? "United States"
                : country?.label,
            time: data?.created_at,
          }).replace("Posted", "Saved")}
        </div>
      </CardCustom>
    );
  };

  return (
    <FilterListStyles>
      <div className="filter-list">
        {backPage && (
          <div className="back-btn-wrapper mb-30">
            <a
              className="shadow-btn"
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  backPage === "search"
                    ? routes.JOBS.SEARCH
                    : routes.JOBS.DASHBOARD
                );
              }}
              href="/#"
              aria-label="Back"
            >
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>
                {backPage === "search" ? "Back to Search Jobs" : "Back to Jobs"}
              </span>
            </a>
          </div>
        )}

        <h2 className="title-primary mb-17">Your Saved Searches</h2>
        {loading ? (
          <div className="filters-shimmer-container">
            {Array.from(Array(3).keys()).map((_, index) => (
              <ShimmerCategoryItem key={index} text cta />
            ))}
          </div>
        ) : (
          <>
            {searchesList?.length > 0 ? (
              <div className="job-alert-list">
                {searchesList.map((data, i) => renderFilterList(data, i))}
              </div>
            ) : (
              <EmptyList title="Looks like you don’t have any saved searches yet!" />
            )}
          </>
        )}
      </div>
    </FilterListStyles>
  );
};
export default FilterList;
