import React from "react";
import { useNavigate } from "react-router-dom";
import profileDownArrowIcon from "../../../../assets/svg/profileDownArrowIcon.svg";
import createJobSearch from "../../../../assets/png/f-create-job-search-setp3.png";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { routes } from "../../../../routes";

const CreateJob = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="status-wrapper">
        <div className="status-title text-primary">How to do it</div>
        <div className="status-sub-title">
          Go to the Jobs portal and fill in the quick search card on the left by
          typing a keyword and choosing a country.
        </div>
        <div className="job-wrapper w-100 pos-relative">
          <img
            className="arrow-icon"
            src={profileDownArrowIcon}
            alt="Down Arrow"
          />
          <img
            className="steps-img step3"
            src={createJobSearch}
            alt="create a job search"
          />
        </div>
        <div className="mandetory-note">
          You can also click on the Job search tab to access our detailed search
          and filter features then save the search.
        </div>
        <ButtonCustom
          onClick={() => navigate(routes.JOBS.DASHBOARD)}
          width={250}
          height={60}
          style={{ marginTop: "20px" }}
        >
          Search Jobs
        </ButtonCustom>
      </div>
    </>
  );
};

export default CreateJob;
